import React, { Component } from "react";
import { getIncomers, getOutgoers, isNode } from "react-flow-renderer";
import { connect } from "react-redux";
import history from "../../../history";
import Cookies from "universal-cookie";

import { Buffer } from "buffer";
import { copy } from "copy-anything";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faFileImport, faExclamation, faQuestionCircle, faSearch, faStore, faCog } from "@fortawesome/free-solid-svg-icons";
import { setBuilderMode, setCommandSaveFailed, setElements, setSelected, setBotModule, saveBot, setBotSettings, setBuilderIndex, cleanUpActionTrees } from "../../../actions";
import server from "../../../api/server";
import CommandImporter from "../elements/CommandImporter";
import ReactGA from "react-ga";
import buildExistingCommand from "./buildExistingCommand";
import getLayoutedElements from "./getLayoutElements";
import { CustomCommandHelper } from "../elements/CustomCommandHelper";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import splitElements from "./splitElements";
import BuilderExitModal from "../elements/BuilderExitModal";
import LZString from "lz-string";
import { layoutElements } from "./eventUtils";
import { CUSTOM_EVENTS } from "../../../variables";
import sha256 from "crypto-js/sha256";
import Base64 from "crypto-js/enc-base64";

import toast, { Toaster } from "react-hot-toast";
import { successToast, errorToast, loadingToast } from "./toast.js";
import moment from 'moment';
import BuilderSettings from "./settings/BuilderSettings";

export class CommandBuilderNav extends Component {
	constructor(props) {
		super(props);

		this.state = {
			saving: false,
			showImportModal: false,
			showExitConfirmation: false,
			showHelper: false,
			showSettings: false,
			lastSaveTime: Date.now(),
			currentTime: Date.now()
		};

		this.saveReminderInterval = null;
		this.timeUpdateInterval = null;
		this.autoSaveInterval = null;
	}

	componentDidMount() {
		const cookies = new Cookies();
		// console.lo
		// if(cookies.get("commandBuilder") == null){
		//     cookies.set("commandBuilder","true")
		//     this.setState({showHelper:true})
		// }

		this.saveReminderInterval = setInterval(this.checkSaveReminder, 30000);
		this.timeUpdateInterval = setInterval(() => {
			this.setState({ currentTime: Date.now() });
		}, 1000);

		// Set up autosave interval to check every 60 seconds instead of every second
		this.autoSaveInterval = setInterval(this.autoSave, 60000); // Changed from 1000 to 60000
	}

	componentDidUpdate(prevProps, prevState) {
		//
		if (this.props.saveRequired) {
			// do A SAVE
			this.save();
			this.props.triggerSave();
		}
	}

	componentWillUnmount() {
		if (this.saveReminderInterval) {
			clearInterval(this.saveReminderInterval);
		}
		if (this.timeUpdateInterval) {
			clearInterval(this.timeUpdateInterval);
		}
		if (this.autoSaveInterval) {
			clearInterval(this.autoSaveInterval);
		}
	}

	renderName = () => {
		var element = this.props.elements[0];
		if (element != undefined) {
			return `/${element.data.title}`;
		} else {
			return null;
		}
	};

	renderDescription = () => {
		var element = this.props.elements[0];
		if (element != undefined) {
			return `${element.data.description}`;
		} else {
			return null;
		}
	};

	onBackClick = () => {
		this.setState({ showExitConfirmation: true });
		// history.push(`/dashboard/${this.props.mode == "event" ? "customevents" : "customcommands"}`);
	};

	importCommand = async (command, type) => {
		if (!("cooldown" in command) && type != "event") {
			command.cooldown = {
				type: "disabled",
				interval: 1,
				interval_type: "minutes"
			};
		}

		var elements = [];
		var errorElements = [];
		if ("compressed_tree" in command && command.compressed_tree != null) {
			var compressedString = LZString.decompressFromBase64(command.compressed_tree);
			var tree = JSON.parse(compressedString);
			elements = tree;
		} else if ("compressed_actions" in command && command.compressed_actions) {
			var compressedString = LZString.decompressFromBase64(command.compressed_actions);
			var actions = JSON.parse(compressedString);
			command.actions = actions;
			var errorElements = [];

			if ("errors" in command) {
				errorElements = await buildExistingCommand(
					{
						options: [],
						actions: command.errors
					},
					type,
					true
				);
			} else {
				errorElements = await buildExistingCommand(
					{
						options: [],
						actions: [
							{
								nodeID: "action_3_6d1a_9853",
								embed: {
									color: "#FF0000",
									description: "{error_reason}",
									title: ":x: {error_message}",
									footer: "{error_block}"
								},
								emojis: [],
								target: { reply: true },
								type: "embed"
							}
						]
					},
					type,
					true
				);
			}

			elements = await buildExistingCommand(command, type);
			elements = elements.concat(errorElements);
		} else {
			elements = await buildExistingCommand(command, type);
		}

		if ("custom_variables" in command) {
			var custom_variables = command.custom_variables;
			var existing_variables = { ...this.props.bot.commands.variables };
			// if (custom_variables) {
			// 	if (!("variables" in bot.bot.commands)) {
			// 		bot.bot.commands.variables = {
			// 			variables: []
			// 		};
			// 	}

			// 	var existing_variables_array = [...bot.bot.commands.variables.variables];
			custom_variables.forEach((variable) => {
				var variable_settings = existing_variables.variables.find((v) => v.reference == variable.reference);
				if (!variable_settings && variable) {
					existing_variables.variables.push(variable);
				}
			});

			this.props.setBotModule({
				module: "variables",
				module_data: existing_variables
			});
			// 	bot.bot.commands.variables.variables = existing_variables_array;
			// 	bot.markModified("bot.commands.variables");
		}

		if (this.props.mode == "event") {
			var event_options = CUSTOM_EVENTS.find((event) => event.value == command.type);

			this.props.setBuilderMode({
				mode: "event",
				components: event_options.components
			});
		}

		// this.props.setBuilderIndex(null);
		// elements = elements.concat(errorElements);
		this.props.setElements(elements);
		var blocks = [...this.props.elements];
		var layout = layoutElements(blocks);
		this.props.setElements(layout);
		this.props.setSelected(null);
		this.props.setSelected("root");
		setTimeout(() => {
			this.setState({ showImportModal: false });
		}, 2000);
		ReactGA.initialize("UA-122665050-1");
		ReactGA.event({
			category: "Import Command",
			label: "Command Imported",
			action: "New Command Builder"
		});
	};

	countActiveCommands = () => {
		var count = 0;
		this.props.slash.commands.forEach((command) => {
			if (command.enabled == undefined || command.enabled == true) {
				count += 1;
			}
		});
		return count;
	};

	countActiveEvents = () => {
		var count = 0;
		this.props.bot.commands.custom_events.events.forEach((event) => {
			if (event.enabled == undefined || event.enabled == true) {
				count += 1;
			}
		});
		return count;
	};

	getCustomVariables = () => {
		var variables = [];
		var variable_actions = ["set_var", "math_var", "delete_var"];
		var variables_array = this.props.bot.commands.variables.variables;
		this.props.elements.forEach((element) => {
			if (isNode(element) && element.type != "root" && element.type == "action" && variable_actions.includes(element.data.data.type)) {
				var action_data = element.data.data;
				var reference = action_data.reference;
				if (reference) {
					var variable_settings = variables_array.find((v) => v.reference == reference);
					if (!variables.includes(variable_settings) && variable_settings) {
						variables.push(variable_settings);
					}
				}
			} else if (element.type == "action") {
				var string = JSON.stringify(element);
				var variablesMatch = string.match(/{BGVAR(.*?)}/g);
				var functionVariables = string.match(/\(BGVAR_([^)]+)\)/g);
				if (variablesMatch) {
					variablesMatch.forEach((variable) => {
						if (variable == "{BGVAR}") return;
						var reference = variable.split("BGVAR_")[1];
						if (!reference) return;
						reference = reference.slice(0, -1);
						reference = reference.split("[")[0];
						var variable_settings = variables_array.find((v) => v.reference == reference);
						if (!variables.includes(variable_settings) && variable_settings) {
							variables.push(variable_settings);
						}
					});
				}
				if (functionVariables) {
					functionVariables.forEach((variable) => {
						var reference = variable.split("BGVAR_")[1];
						if (!reference) return;
						reference = reference.slice(0, -1);
						var variable_settings = variables_array.find((v) => v.reference == reference);
						if (!variables.includes(variable_settings) && variable_settings) {
							variables.push(variable_settings);
						}
					});
				}
			}
		});

		return variables;
	};

	validateSubcommands = (commandName) => {
		// First check for trailing/multiple whitespace
		const trimmedName = commandName.trim();
		if (trimmedName !== commandName || commandName.includes("  ")) {
			return {
				valid: false,
				message: `Invalid command name format: Command names cannot have trailing or multiple spaces.

• Current name: "${commandName}"
• Correct format: "${trimmedName.replace(/\s+/g, " ")}"`
			};
		}

		// Get all commands from current context
		let commands = [];
		if (this.props.module_id != null) {
			commands = this.props.moduleSettings[this.props.module_id].commands;
		} else {
			commands = this.props.slash.commands;
		}

		// Get all commands from enabled modules
		let moduleCommands = [];
		if (this.props.bot.commands.moduleSettings) {
			Object.entries(this.props.bot.commands.moduleSettings).forEach(([moduleId, module]) => {
				if (moduleId === this.props.module_id) return;
				if (module.enabled) {
					moduleCommands = moduleCommands.concat(
						module.commands.map(cmd => ({
							...cmd,
							fromModule: moduleId
						}))
					);
				}
			});
		}

		// Combine all commands we need to check against
		let allCommands = [...commands, ...moduleCommands];

		// Skip current command if editing
		if (this.props.index != null) {
			allCommands = allCommands.filter((cmd) => {
				if (this.props.module_id != null) {
					return cmd.fromModule || cmd !== commands[this.props.index];
				}
				return cmd.fromModule || cmd !== commands[this.props.index];
			});
		}

		// Split the new command name into parts
		const newCommandParts = trimmedName.toLowerCase().split(" ");

		// Check each existing command
		for (const command of allCommands) {
			const existingParts = command.name.trim().toLowerCase().split(" ");

			// Check for exact duplicate command names
			if (newCommandParts.join(" ") === existingParts.join(" ")) {
				const moduleText = command.fromModule ? ' in another module' : '';
				return {
					valid: false,
					message: `Cannot save command "/${newCommandParts.join(" ")}" because this exact command name already exists${moduleText}.

To fix this: Choose a different name for your command.`
				};
			}

			// Case 1: New command is a parent, check if it conflicts with existing subcommands
			if (newCommandParts.length === 1) {
				if (existingParts[0] === newCommandParts[0] && existingParts.length > 1) {
					const moduleText = command.fromModule ? ' in another module' : '';
					return {
						valid: false,
						message: `Cannot save command "/${newCommandParts[0]}" because it conflicts with an existing subcommand "/${command.name}"${moduleText}.

To fix this: Either rename your command or make it a subcommand by adding a word after "/${newCommandParts[0]}".`
					};
				}
			}

			// Case 2: New command is a subcommand, check if parent exists as standalone
			if (newCommandParts.length > 1) {
				if (existingParts.length === 1 && existingParts[0] === newCommandParts[0]) {
					const moduleText = command.fromModule ? ' in another module' : '';
					return {
						valid: false,
						message: `Cannot save subcommand "/${newCommandParts.join(" ")}" because "/${existingParts[0]}"${moduleText} exists as a standalone command.

To fix this: Either rename your subcommand to use a different parent command, or delete the existing "/${existingParts[0]}" command first.`
					};
				}
			}
		}

		return {
			valid: true
		};
	};

	validateSave = () => {
		let failed = false;

		// Check if mode is event and server ID is required
		if (this.props.mode == "event") {
			const root = this.props.elements.find((element) => element.type == "root");
			const event_options = CUSTOM_EVENTS.find((event) => event.value == root.data.data.type);
			if (event_options.server_required && (!root.data.data.guild_id || root.data.data.guild_id == "")) {
				return false;
			}

			if (root.data.data.type == "IFTTTActionEvent" && !root.data.data.iftttId) {
				return false;
			}
		}

		// Check premium limits
		if (this.props.index == null && this.props.premium == false) {
			if (this.props.mode == "event" && this.countActiveEvents() >= 3) {
				return false;
			} else if (this.props.mode != "event" && this.countActiveCommands() >= 8) {
				return false;
			}
		}

		// Validate all elements
		for (const element of this.props.elements) {
			if (isNode(element) && element.type != "root" && element.type != "loopChild") {
				if (element.data.data == undefined || element.data.data.validated == false) {
					return false;
				}
				if ((element.type == "action" || element.type == "condition")) {
					const incomers = getIncomers(element, splitElements(this.props.elements).nodes, splitElements(this.props.elements).edges);
					if (incomers.length == 0) {
						return false;
					}
				}
			} else if (element.type == "root") {
				if (element.data.title == "" || element.data.description == "") {
					return false;
				}
			}
		}

		return true;
	};

	autoSave = () => {
		const { builder_settings } = this.props;

		// Check if autosave is enabled and we have settings
		if (!builder_settings?.autoSave || !this.props.premium) {
			return;
		}

		// Get interval (minimum 60 seconds)
		const interval = Math.max(60, builder_settings.autoSaveInterval || 60);

		// Check if enough time has passed since last save
		const timeSinceLastSave = Date.now() - this.state.lastSaveTime;
		if (timeSinceLastSave < interval * 1000) {
			return;
		}

		// Call existing save method with isAutoSave flag
		this.save(true);
	};

	save = (isAutoSave = false) => {
		// Validate
		if (!this.validateSave()) {
			if (!isAutoSave) {
				// Show validation errors only for manual saves
				this.showValidationErrors();
			}
			this.props.setCommandSaveFailed(true);
			return;
		}

		// Save if validation passed
		const custom_variables = this.getCustomVariables();
		this.buildCommand(custom_variables, isAutoSave);
		this.props.setCommandSaveFailed(false);
	};

	// Add new method to show validation errors
	showValidationErrors = () => {
		const root = this.props.elements.find((element) => element.type == "root");

		// Check event requirements
		if (this.props.mode == "event") {
			const event_options = CUSTOM_EVENTS.find((event) => event.value == root.data.data.type);
			if (event_options.server_required && (!root.data.data.guild_id || root.data.data.guild_id == "")) {
				this.props.setSelected(root.id);
				return errorToast("This event type requires a discord server id to be set.", {
					position: "top-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined
				});
			}

			if (root.data.data.type == "IFTTTActionEvent" && !root.data.data.iftttId) {
				this.props.setSelected(root.id);
				return errorToast("This event type requires an IFTTT event id to be set.", {
					position: "top-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined
				});
			}
		}

		// Check premium limits
		if (this.props.index == null && this.props.premium == false) {
			if (this.props.mode == "event" && this.countActiveEvents() >= 3) {
				return errorToast("Non premium users are limited to 3 active custom events only. Upgrade to Premium to create unlimited custom events.", {
					position: "top-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined
				});
			} else if (this.props.mode != "event" && this.countActiveCommands() >= 8) {
				return errorToast("Non premium users are limited to 8 active commands only. Upgrade to Premium to create unlimited commands.", {
					position: "top-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined
				});
			}
		}

		// Check elements
		for (const element of this.props.elements) {
			if (isNode(element) && element.type != "root" && element.type != "loopChild") {
				if (element.data.data == undefined || element.data.data.validated == false) {
					this.props.setSelected(element.id);
					return errorToast(
						<span>
							Please complete the settings for your <strong>{element.data.node_options.title}</strong> {element.type}.
						</span>,
						{
							position: "top-right",
							autoClose: 5000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined
						}
					);
				}
				if ((element.type == "action" || element.type == "condition")) {
					const incomers = getIncomers(element, splitElements(this.props.elements).nodes, splitElements(this.props.elements).edges);
					if (incomers.length == 0) {
						this.props.setSelected(element.id);
						return errorToast(
							<span>
								Please connect your <strong>{element.data.node_options.title}</strong> {element.type} to the command tree.
							</span>,
							{
								position: "top-right",
								autoClose: 5000,
								hideProgressBar: false,
								closeOnClick: true,
								pauseOnHover: true,
								draggable: true,
								progress: undefined
							}
						);
					}
				}
			} else if (element.type == "root") {
				if (element.data.title == "") {
					this.props.setSelected(element.id);
					return errorToast("Please fill in the trigger for your command.");
				} else if (element.data.description == "") {
					this.props.setSelected(element.id);
					return errorToast("Please give a description for your command.", {
						position: "top-right",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined
					});
				}
			}
		}
	};

	saveBot = async (isAutoSave = false) => {
		this.setState({ saving: true });
		var cleanedBot = cleanUpActionTrees(this.props.bot);

		var obj = {
			bot: cleanedBot
		};

		obj.collab = this.props.collab ? true : false;

		const response = await server.post("/dashboard/save", obj).catch((e) => { });

		if (response == undefined) {
			this.setState({ disabled: false });
			reason = "Network Error: Please try again in a few seconds.";
			errorToast(`Save Failed: ${reason}`, {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		} else if (response.data.updated == true) {
			this.props.saveBot({ ...this.props.bot });
			this.setState({
				saving: false,
				lastSaveTime: Date.now()
			});

			// Only show success toast if not autosaving
			if (!isAutoSave) {
				successToast(
					`${this.props.mode == "event" ? "Event" : "Command"} Saved`,
					`Your ${this.props.mode == "event" ? "event" : "command"} has been saved and updated.`,
					this.props.mode == "event" ? false : true
				);
			}
		} else {
			var reason = "";
			if (response.data.reason == "validation") {
				reason = "Your bot's token could not be validated. Please double check you have pasted your bots Token and not its id.";
				const token = this.props.pre_save.token;
				history.push("/dashboard/settings");
				this.props.setBotSettings({ value: token, key: "token" });
			} else if (response.data.reason == "token") {
				reason = "You did not provide your bots token. Please read the Getting Started section under Dashboard to get your Bot's Token.";
				const token = this.props.pre_save.token;
				history.push("/dashboard/settings");
				this.props.setBotSettings({ value: token, key: "token" });
			} else if (response.data.reason == "exists") {
				reason = "A bot with this token already exists on your account.";
				const token = this.props.pre_save.token;
				history.push("/dashboard/settings");
				this.props.setBotSettings({ value: token, key: "token" });
			} else if (response.data.reason == "cocreator_disabled") {
				reason = "The author of this bot has disabled cocreator.";
			}
			errorToast(`Save Failed: ${reason}`, {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined
			});
		}
		this.setState({ saving: false });
	};

	buildCommand = (custom_variables, isAutoSave) => {
		var object = {
			name: "",
			id: "",
			description: "",
			options: [],
			actions: [],
			permissions: {
				allowed_roles: [{ name: "@everyone", id: "everyone" }],
				banned_roles: [],
				banned_channels: [],
				banned_users: [],
				required_permissions: []
			},
			ephemeral: false,
			error_ephemeral: true,
			custom_variables: custom_variables
		};

		var elements = [...this.props.elements];
		var options = elements.filter((element) => element.type == "option");
		var root = elements.find((element) => element.type == "root");

		// Validate subcommands before proceeding


		if (this.props.mode != "event") {
			const validation = this.validateSubcommands(root.data.title);
			if (!validation.valid) {
				errorToast(validation.message, {
					position: "top-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined
				});
				this.props.setCommandSaveFailed(true);
				return;
			}
			object.name = root.data.title;

			object.description = root.data.description;
			object.permissions = root.data.data.permissions;
			object.cooldown = root.data.data.cooldown;
			object.ephemeral = root.data.data.ephemeral;
			object.error_ephemeral = root.data.data.error_ephemeral != undefined ? root.data.data.error_ephemeral : true;
			options.forEach((option) => {
				object.options.push(option.data.data);
			});
		}


		if (this.props.mode == "event") {
			object = {
				name: root.data.data.name,
				type: root.data.data.type,
				guild_id: root.data.data.guild_id,

				iftttId: root.data.data.iftttId ? root.data.data.iftttId : null,
				webhookId: root.data.data.webhookId ? root.data.data.webhookId : null,
				timer_id: root.data.data.timer_id ? root.data.data.timer_id : null,
				error_ephemeral: root.data.data.error_ephemeral != undefined ? root.data.data.error_ephemeral : true,
				event: true,
				enabled: true,
				options: [],
				actions: [],
				custom_variables: custom_variables
			};

			if (root.data.data.global_event != undefined) {
				object.global_event = root.data.data.global_event;
			}
		}

		if (this.props.groupId != null) {
			object.groupId = this.props.groupId;
		}


		var errorHandlingNode = elements.find((element) => element.id == "error_handler");
		if (errorHandlingNode != undefined) {
			// var error_actions = [];
			// var error_children = getOutgoers(errorHandlingNode, splitElements(this.props.elements).nodes, splitElements(this.props.elements).edges);
			// while (error_children.length > 0) {
			// 	var element = error_children[0];
			// 	element.data.data.nodeID = element.id;
			// 	if (element.type == "condition") {
			// 		error_actions.push(this.buildConditionAction(element));
			// 		error_children = [];
			// 	} else if (element.data.node_options.title == "Send a Message with attached Buttons") {
			// 		error_actions.push(this.buildButtonAction(element));
			// 		error_children = [];
			// 	} else if (element.data.node_options.title == "Send a Message with a Select Menu") {
			// 		error_actions.push(this.buildSelectMenuAction(element));
			// 		error_children = [];
			// 	} else if (element.data.node_options.title == "Run a Loop") {
			// 		error_actions.push(this.buildLoopAction(element));
			// 		var loop_after = this.getLoopChildren(element).loop_after;
			// 		error_children = getOutgoers(loop_after, splitElements(this.props.elements).nodes, splitElements(this.props.elements).edges);
			// 	} else {
			// 		error_actions.push(element.data.data);
			// 		error_children = getOutgoers(element, splitElements(this.props.elements).nodes, splitElements(this.props.elements).edges);
			// 	}
			// }

			// // Compress error actions
			// var error_actions_string = JSON.stringify(error_actions);
			// var compressed_error_actions = LZString.compressToBase64(error_actions_string);

			// Add compressed errors and flag
			// object.compressed_errors = compressed_error_actions;
			object.handleErrors = true;
			object.errors_in_tree = true;

			// // Generate hash for error actions
			// var errorHashDigest = sha256(compressed_error_actions);
			// var errorHash = Base64.stringify(errorHashDigest);
			// object.errors_hash = errorHash;
		}

		var copied_elements = copy(this.props.elements);
		// var tree_string = JSON.stringify(copied_elements);

		var biggest = 0;
		var biggest_element = null;
		var trimmed_elements = [];

		var test_elements = [];

		copied_elements.forEach((element) => {
			if (element.data && element.type != "loopChild") {
				var trimmed_element = copy(element);
				delete trimmed_element.data.data.actions;
				delete trimmed_element.data.data.buttons;
				delete trimmed_element.data.data.conditions;
				delete trimmed_element.data.data.options;
				delete trimmed_element.data.data.else;
				var size = JSON.stringify(trimmed_element).length;
				if (size > biggest) {
					biggest = size;
					biggest_element = trimmed_element;
				}

				trimmed_elements.push(trimmed_element);
			} else {
				trimmed_elements.push(element);
			}
		});


		// });


		var tree_string = JSON.stringify(trimmed_elements);
		var compressed_tree_string = LZString.compressToBase64(tree_string);

		var hashDigest = sha256(compressed_tree_string);
		var hash = Base64.stringify(hashDigest);
		object.compressed_tree = compressed_tree_string;
		object.actions_hash = hash;


		var future = this.props.future;
		var future_string = JSON.stringify(future);
		var compressed_future_string = LZString.compressToBase64(future_string);

		object.future_string = compressed_future_string;



		delete object.actions;
		delete object.compressed_actions;

		console.log(object);



		if (this.props.mode == "event") {
			// Custom Event
			const custom_events = { ...this.props.bot.commands.custom_events };
			if (this.props.module_id != null) {
				// Custom Module Here
				if (this.props.slot_id != null) {
					object.slot_id = this.props.slot_id;
				}
				object.module_id = this.props.module_id;
				object.module_edited = true;
				var module_settings = this.props.moduleSettings;
				var moduleCommandIndex = this.props.index;
				// If the index is greater than the length of the commands, then it is a new command and should be pushed to the end of the array
				if (moduleCommandIndex >= module_settings[this.props.module_id].events.length) {
					module_settings[this.props.module_id].events.push(object);
				} else {
					module_settings[this.props.module_id].events[this.props.index] = object;
				}

				this.props.setBotModule({
					module: "moduleSettings",
					module_data: module_settings
				});
				return this.saveBot(isAutoSave);
			}

			var id = "";
			if (this.props.index != null && this.props.index != undefined) {
				var id = custom_events.events[this.props.index].id;
			}
			object.id = id;

			if (this.props.index == null) {
				ReactGA.initialize("UA-122665050-1");
				if (this.props.marketId) {
					object.marketImported = true;
					server.get("/market/builderImport/" + this.props.marketId);
					ReactGA.event({
						category: "Market",
						label: this.props.marketId,
						action: "Import Command"
					});
					ReactGA.event({
						category: "Custom Event",
						label: "Created",
						action: "Import from Market"
					});
				} else {
					ReactGA.event({
						category: "Custom Event",
						label: "Created",
						action: "New Command Builder"
					});
				}
				// Check if its first command
				if (custom_events.events.length == 0 && window.sa_loaded) {
					try {
						window.sa_event("first_event");
					} catch (e) {
						// console.log(e);
					}
				}
				if (window.sa_loaded) {
					try {
						window.sa_event("new_event");
					} catch (e) {
						// console.log(e);
					}
				}
				custom_events.events.push(object);

				this.props.setBuilderIndex(custom_events.events.length - 1);
			} else {
				if ("enabled" in custom_events.events[this.props.index]) {
					object.enabled = custom_events.events[this.props.index].enabled;
				}
				custom_events.events[this.props.index] = object;
			}
			// console.log(object, 'EVENT OBJECT');

			// Go through and check if actions_hash exists in any other events
			custom_events.events.forEach((cEvent) => {
				// If no actions_hash exists, add it
				if (!cEvent.actions_hash && cEvent.compressed_tree) {
					var hashDigest = sha256(cEvent.compressed_tree);
					var hash = Base64.stringify(hashDigest);
					cEvent.actions_hash = hash;
				}
			});

			this.props.setBotModule({
				module: "custom_events",
				module_data: custom_events
			});
			if (id != "") {
				const response = server.post("/dashboard/updateCommand", { id: id, command: object }).catch((e) => { });
			}
			this.saveBot(isAutoSave);
		} else {
			// Custom Command
			const data = { ...this.props.slash };

			if (this.props.module_id != null) {
				// Custom Module Here
				if (this.props.slot_id != null) {
					object.slot_id = this.props.slot_id;
				}
				// console.log(this.props.moduleSettings, 'MODULE SETTINGS');
				object.module_id = this.props.module_id;
				object.module_edited = true;
				var module_settings = this.props.moduleSettings;
				var moduleCommandIndex = this.props.index;

				if (this.props.index != null && this.props.index != undefined) {
					var module_commands = module_settings[this.props.module_id].commands;
					if (module_commands[this.props.index]) {
						console.log(module_commands[this.props.index].int_id, 'int_id 2');
						object.int_id = module_commands[this.props.index].int_id;
					}
				}
				// If the index is greater than the length of the commands, then it is a new command and should be pushed to the end of the array
				if (moduleCommandIndex >= module_settings[this.props.module_id].commands.length) {
					module_settings[this.props.module_id].commands.push(object);
				} else {
					module_settings[this.props.module_id].commands[this.props.index] = object;
				}

				// Set int_id if it exists


				this.props.setBotModule({
					module: "moduleSettings",
					module_data: module_settings
				});
				return this.saveBot(isAutoSave);
			}

			var id = "";
			if (this.props.index != null && this.props.index != undefined) {
				console.log("INSIDE HERE??!");
				var id = this.props.slash.commands[this.props.index].id;
				var int_id = this.props.slash.commands[this.props.index].int_id;
				object.int_id = int_id;
			} else {
				console.log("NOT INSIDE HERE??!");
			}
			object.id = id;


			if (this.props.index == null) {
				if (this.props.marketId) {
					object.marketImported = true;
				}

				// Check if its first command
				try {
					if (data.commands.length == 0 && window.sa_loaded) {
						window.sa_event("first_command");
					}
					if (window.sa_loaded) {
						window.sa_event("new_command");
					}
				} catch (e) { }
				data.commands.push(object);

				this.props.setBuilderIndex(data.commands.length - 1);
				ReactGA.initialize("UA-122665050-1");
				if (this.props.marketId) {
					server.get("/market/builderImport/" + this.props.marketId);
					ReactGA.event({
						category: "Market",
						label: this.props.marketId,
						action: "Import Command"
					});
					ReactGA.event({
						category: "Custom Command",
						label: "Created",
						action: "Import from Market"
					});
				} else {
					ReactGA.event({
						category: "Custom Command",
						label: "Created",
						action: "New Command Builder"
					});
				}
			} else {
				if ("enabled" in data.commands[this.props.index]) {
					object.enabled = data.commands[this.props.index].enabled;
				}
				data.commands[this.props.index] = object;

				// for (var i = 0; i < data.commands.length; i++) {
				//     var customCommand = data.commands[i];
				//     if (customCommand.actions) {
				//         var actions_string = JSON.stringify(customCommand.actions);
				//         customCommand.compressed_actions = LZString.compressToBase64(actions_string);
				//         delete customCommand.actions;
				//     }
				// }
			}

			// Go through and check if actions_hash exists in any other events
			data.commands.forEach((cCommand) => {
				// If no actions_hash exists, add it
				if (!cCommand.actions_hash && cCommand.compressed_tree) {
					var hashDigest = sha256(cCommand.compressed_tree);
					var hash = Base64.stringify(hashDigest);
					cCommand.actions_hash = hash;
				}
			});

			this.props.setBotModule({
				module: "slash",
				module_data: data
			});

			if (id != "") {
				const response = server.post("/dashboard/updateCommand", { id: id, command: object }).catch((e) => { });
			}

			this.saveBot(isAutoSave);
		}
	};

	buildButtonAction = (element) => {
		var data = { ...element.data.data };
		delete data.validated;
		data.buttons = [];
		var buttons = getOutgoers(element, splitElements(this.props.elements).nodes, splitElements(this.props.elements).edges);
		buttons.forEach((button) => {
			button.data.data.nodeID = button.id;
			delete button.data.data.validated;
			button.data.data.actions = [];
			var children = getOutgoers(button, splitElements(this.props.elements).nodes, splitElements(this.props.elements).edges);
			while (children.length > 0) {
				var element = children[0];
				element.data.data.nodeID = element.id;
				if (element.type == "condition") {
					button.data.data.actions.push(this.buildConditionAction(element));
					children = [];
				} else if (element.data.node_options.title == "Send a Message with attached Buttons") {
					button.data.data.actions.push(this.buildButtonAction(element));
					children = [];
				} else if (element.data.node_options.title == "Send a Message with a Select Menu") {
					button.data.data.actions.push(this.buildSelectMenuAction(element));
					children = [];
				} else if (element.data.node_options.title == "Run a Loop") {
					button.data.data.actions.push(this.buildLoopAction(element));
					var loop_after = this.getLoopChildren(element).loop_after;
					children = getOutgoers(loop_after, splitElements(this.props.elements).nodes, splitElements(this.props.elements).edges);
				} else {
					delete element.data.data.validated;
					button.data.data.actions.push(element.data.data);
					children = getOutgoers(element, splitElements(this.props.elements).nodes, splitElements(this.props.elements).edges);
				}
			}
			data.buttons.push(button.data.data);
		});
		return data;
	};

	getLoopChildren = (element) => {
		var loopChildren = getOutgoers(element, splitElements(this.props.elements).nodes, splitElements(this.props.elements).edges);
		// console.log(loopChildren, 'LOOP CHILDREN');
		var loop_actions = null;
		var loop_after = null;
		loopChildren.forEach((loopChild) => {
			if (loopChild.data.type == "loop_actions") {
				loop_actions = loopChild;
			} else {
				loop_after = loopChild;
			}
		});

		return {
			loop_actions: loop_actions,
			loop_after: loop_after
		};
	};

	buildLoopAction = (element) => {
		var data = { ...element.data.data };
		data.actions = [];
		delete data.validated;
		var loop_actions = this.getLoopChildren(element).loop_actions;

		var loopActionChildren = getOutgoers(loop_actions, splitElements(this.props.elements).nodes, splitElements(this.props.elements).edges);

		while (loopActionChildren.length > 0) {
			var element = loopActionChildren[0];
			element.data.data.nodeID = element.id;
			if (element.type == "condition") {
				data.actions.push(this.buildConditionAction(element));
				loopActionChildren = [];
			} else if (element.data.node_options.title == "Send a Message with attached Buttons") {
				data.actions.push(this.buildButtonAction(element));
				loopActionChildren = [];
			} else if (element.data.node_options.title == "Send a Message with a Select Menu") {
				data.actions.push(this.buildSelectMenuAction(element));
				loopActionChildren = [];
			} else if (element.data.node_options.title == "Run a Loop") {
				data.actions.push(this.buildLoopAction(element));
				var loop_after = this.getLoopChildren(element).loop_after;
				loopActionChildren = getOutgoers(loop_after, splitElements(this.props.elements).nodes, splitElements(this.props.elements).edges);
			} else {
				delete element.data.data.validated;
				data.actions.push(element.data.data);
				loopActionChildren = getOutgoers(element, splitElements(this.props.elements).nodes, splitElements(this.props.elements).edges);
			}
		}

		return data;
	};

	buildSelectMenuAction = (element) => {
		var data = { ...element.data.data };
		delete data.validated;
		data.options = [];
		var menuOptions = getOutgoers(element, splitElements(this.props.elements).nodes, splitElements(this.props.elements).edges);
		menuOptions.forEach((menuOption) => {
			menuOption.data.data.nodeID = menuOption.id;
			delete menuOption.data.data.validated;
			menuOption.data.data.actions = [];
			var children = getOutgoers(menuOption, splitElements(this.props.elements).nodes, splitElements(this.props.elements).edges);
			while (children.length > 0) {
				var element = children[0];
				element.data.data.nodeID = element.id;
				if (element.type == "condition") {
					menuOption.data.data.actions.push(this.buildConditionAction(element));
					children = [];
				} else if (element.data.node_options.title == "Send a Message with attached Buttons") {
					menuOption.data.data.actions.push(this.buildButtonAction(element));
					children = [];
				} else if (element.data.node_options.title == "Send a Message with a Select Menu") {
					menuOption.data.data.actions.push(this.buildSelectMenuAction(element));
					children = [];
				} else if (element.data.node_options.title == "Run a Loop") {
					menuOption.data.data.actions.push(this.buildLoopAction(element));
					var loop_after = this.getLoopChildren(element).loop_after;
					children = getOutgoers(loop_after, splitElements(this.props.elements).nodes, splitElements(this.props.elements).edges);
				} else {
					delete element.data.data.validated;
					menuOption.data.data.actions.push(element.data.data);
					children = getOutgoers(element, splitElements(this.props.elements).nodes, splitElements(this.props.elements).edges);
				}
			}
			data.options.push(menuOption.data.data);
		});
		return data;
	};

	buildConditionAction = (element) => {
		var data = { ...element.data.data };
		data.conditions = [];
		data.else = [];
		var conditions = getOutgoers(element, splitElements(this.props.elements).nodes, splitElements(this.props.elements).edges);
		conditions.forEach((condition) => {
			delete condition.data.data.validated;
			condition.data.data.actions = [];
			// condition.data.data = condition.id;
			var children = getOutgoers(condition, splitElements(this.props.elements).nodes, splitElements(this.props.elements).edges);
			while (children.length > 0) {
				var element = children[0];
				element.data.data.nodeID = element.id;
				if (element.type == "condition") {
					condition.data.data.actions.push(this.buildConditionAction(element));
					children = [];
				} else if (element.data.node_options.title == "Send a Message with attached Buttons") {
					condition.data.data.actions.push(this.buildButtonAction(element));
					children = [];
				} else if (element.data.node_options.title == "Send a Message with a Select Menu") {
					condition.data.data.actions.push(this.buildSelectMenuAction(element));
					children = [];
				} else if (element.data.node_options.title == "Run a Loop") {
					condition.data.data.actions.push(this.buildLoopAction(element));
					var loop_after = this.getLoopChildren(element).loop_after;
					children = getOutgoers(loop_after, splitElements(this.props.elements).nodes, splitElements(this.props.elements).edges);
				} else {
					delete element.data.data.validated;
					condition.data.data.actions.push(element.data.data);
					children = getOutgoers(element, splitElements(this.props.elements).nodes, splitElements(this.props.elements).edges);
				}
			}

			if (condition.data.data.type == "else") {
				data.else = {
					option: condition.data.data.option,
					actions: condition.data.data.actions,
					nodeID: condition.id
				};
			} else {
				condition.data.data.nodeID = condition.id;
				data.conditions.push(condition.data.data);
			}
		});
		return data;
	};

	showHelp = () => {
		this.setState({ showHelper: true });
	};

	renderMarketButton = () => {
		if (this.props.marketId) {
			if (this.props.mode == "event") {
				return (
					<a style={{ marginRight: "15px", backgroundColor: "#3281eb", padding: "5px 25px", color: "#fff" }} className="btn command-builder-save" type="button" href={`https://botghost.com/market/event/${this.props.marketId}`} target="_blank">
						<FontAwesomeIcon icon={faStore} /> View Event Page
					</a>
				);
			} else {
				return (
					<a style={{ marginRight: "15px", backgroundColor: "#3281eb", padding: "5px 25px", color: "#fff" }} className="btn command-builder-save" type="button" href={`https://botghost.com/market/command/${this.props.marketId}`} target="_blank">
						<FontAwesomeIcon icon={faStore} /> View Command Page
					</a>
				);
			}
		} else {
			if (this.props.mode == "event") {
				return (
					<a style={{ marginRight: "15px", backgroundColor: "#3281eb", padding: "5px 25px", color: "#fff" }} className="btn command-builder-save" type="button" href="https://botghost.com/market" target="_blank">
						<FontAwesomeIcon icon={faStore} /> Find Events
					</a>
				);
			} else {
				return (
					<a style={{ marginRight: "15px", backgroundColor: "#3281eb", padding: "5px 25px", color: "#fff" }} className="btn command-builder-save" type="button" href="https://botghost.com/market" target="_blank">
						<FontAwesomeIcon icon={faStore} /> Find Commands
					</a>
				);
			}
		}
	};

	render() {
		return (
			<div className="command-builder-nav">
				{this.state.showImportModal == true ? (
					<CommandImporter
						mode={this.props.mode}
						importCommand={this.importCommand}
						closeModal={(e) => {
							this.setState({ showImportModal: false });
						}}
					/>
				) : null}
				{this.state.showHelper == true ? (
					<CustomCommandHelper
						closeModal={(e) => {
							this.setState({ showHelper: false });
						}}
					/>
				) : null}
				{this.state.showExitConfirmation ? (
					<BuilderExitModal
						closeModal={(e) => {
							this.setState({ showExitConfirmation: false });
						}}
					/>
				) : null}
				<div className="command-builder-nav-left">
					<div style={{ display: "flex", height: "100%", alignItems: "center" }}>
						<div
							className="back"
							style={{ cursor: "pointer" }}
							onClick={() => {
								this.onBackClick();
							}}
						>
							<img src={process.env.PUBLIC_URL + "/images/arrow.svg"} />
						</div>

						{/* <div className="command-builder-nav-details">
                            <p className="title">{this.renderName()}</p>
                            <p className="description">{this.renderDescription()}</p>
                        </div> */}

						<div style={{ marginLeft: "15px" }}>
							<button
								style={{ marginRight: "15px" }}
								className="btn btn-blue command-builder-save"
								type="button"
								onClick={(e) => {
									this.setState({ showImportModal: true });
								}}
							>
								<FontAwesomeIcon icon={faFileImport} /> Import {this.props.mode == "event" ? "Event" : "Command"}
							</button>

							<a style={{ marginRight: "15px" }} className="btn btn-blue command-builder-save" type="button" href="https://docs.botghost.com/custom-commands/command-builder" target="_blank">
								<FontAwesomeIcon icon={faQuestionCircle} /> Docs
							</a>

							<button
								className="btn btn-blue command-builder-save"
								style={{ marginRight: "15px" }}
								onClick={() => this.setState({ showSettings: true })}
							>
								<FontAwesomeIcon icon={faCog} /> Settings
							</button>
						</div>
					</div>
				</div>

				<div className="command-builder-nav-right">
					<div className="flex items-center">
						<div className="mr-6">
							{this.state.lastSaveTime && (
								<span style={{
									color: "#6B7280",
									fontSize: "14px",
									fontWeight: "500"
								}}>
									Last saved: {moment(this.state.lastSaveTime).fromNow()}
								</span>
							)}
						</div>

						{this.state.currentTime - this.state.lastSaveTime >= 30000 && (
							<div className="mr-6 animate-pulse">
								<span style={{
									color: "#EF4444",
									fontSize: "14px",
									display: "flex",
									alignItems: "center",
									fontWeight: "500"
								}}>
									<FontAwesomeIcon icon={faSave} style={{ marginRight: "8px" }} />
									Don't forget to save your changes!
								</span>
							</div>
						)}

						{/* {this.renderMarketButton()} */}
						{/* <button
							className="btn mr-4 px-4 py-2 bg-gray-700 hover:bg-gray-600 text-white rounded flex items-center gap-2"
							onClick={() => this.setState({ showSettings: true })}
						>
							<FontAwesomeIcon icon={faCog} />
							Settings
						</button> */}
						<button
							disabled={this.state.saving}
							id="command-builder-save"
							style={{
								backgroundColor: this.state.saving ? "#86EFAC" : "#EF4444",
								color: "#FFFFFF",
								padding: "8px 16px",
								borderRadius: "4px",
								display: "flex",
								alignItems: "center",
								gap: "8px"
							}}
							onClick={(e) => {
								this.save();
							}}
							className="btn command-builder-save"
						>
							<FontAwesomeIcon icon={faSave} />
							{this.state.saving ? "Saving..." : `Save ${this.props.mode == "event" ? "Event" : "Command"}`}
						</button>
					</div>
				</div>
				{/* <div id="leftside">
                    <div id="details">
                        <div id="back"><img src="assets/arrow.svg" /></div>
                        <div id="names">
                            <p id="title">Your automation pipeline</p>
                            <p id="subtitle">Marketing automation</p>
                        </div>
                    </div>
                </div> */}
				{this.state.showSettings && (
					<BuilderSettings
						open={this.state.showSettings}
						closeModal={() => this.setState({ showSettings: false })}
					/>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	elements: state.builder.elements,
	slash: state.data.bot.commands.slash,
	bot: state.data.bot,
	pre_save: state.data.pre_save,
	cocreate: state.data.co_create,
	bots: state.data.bots,
	premium: state.data.premium,
	index: state.builder.index,
	mode: state.builder.mode,
	beta: state.data.beta,
	marketId: state.builder.marketId,
	module_id: state.builder.module_id,
	slot_id: state.builder.slot_id,
	collab: state.data.collab,
	moduleSettings: state.data.bot.commands.moduleSettings,
	future: state.builder.future,
	groupId: state.builder.groupId,
	builder_settings: state.data.user.builder_settings
});

const mapDispatchToProps = {
	setSelected,
	setBotModule,
	saveBot,
	setBotSettings,
	setElements,
	setBuilderIndex,
	setCommandSaveFailed,
	setBuilderMode
};

var hashCode = function (s) {
	return s.split("").reduce(function (a, b) {
		a = (a << 5) - a + b.charCodeAt(0);
		return a & a;
	}, 0);
};
export default connect(mapStateToProps, mapDispatchToProps)(CommandBuilderNav);